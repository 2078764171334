import React, { useState, useEffect } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import slugify from 'slugify';
import { Image, Link } from 'components';
import { answers } from '../../statementsAndAnswers';
import chevron from '../../images/chevron.svg';
import shuffle from '../../images/shuffle.svg';
import undo from '../../images/undo.svg';
import twitter from '../../images/twitter.svg';
import drag from '../../images/drag.svg';
import './styles.scss';

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const getFiveCards = () => {
  const shuffled = answers.sort(() => 0.5 - Math.random());

  const selected = shuffled.slice(0, 5);
  return selected;
};

const getNewCards = (activeCards, numberToFetch) => {
  const filteredAnswers = answers.filter(answer => activeCards.indexOf(answer) === -1);
  const shuffled = filteredAnswers.sort(() => 0.5 - Math.random());

  const newCard = shuffled.slice(0, numberToFetch);
  return newCard;
};

/**
 * Moves an item from one list to another list.
 */
const move = (source, destination, droppableSource, droppableDestination) => {
  const sourceClone = Array.from(source);
  const destClone = Array.from(destination);
  const [removed] = sourceClone.splice(droppableSource.index, 1);

  destClone.splice(droppableDestination.index, 0, removed);

  const result = {};
  result[droppableSource.droppableId] = sourceClone;
  result[droppableDestination.droppableId] = destClone;

  return result;
};

const emptyArray = () => [];

const AnswerPicker = ({
  answerCount,
  answerArray,
  newStatement,
  statement,
  statementComplete,
  finalStatement,
  finalHtmlStatement,
}) => {
  const freshDeck = {
    answerCards: getFiveCards(),
    selectedAnswerCard: emptyArray(),
  };

  const defaultPlaceholderProps = {
    answerCards: {},
    selectedAnswerCard: {},
  };

  const [cards, setCards] = useState(freshDeck);
  const [animateAnswerDrop, setAnimateAnswerDrop] = useState(false);
  const [showPrompt, setShowPrompt] = useState(true);
  const [placeholderProps, setPlaceholderProps] = useState(defaultPlaceholderProps);

  const queryAttr = 'data-rbd-drag-handle-draggable-id';

  useEffect(() => {
    if (!showPrompt) {
      setAnimateAnswerDrop(true);
      const responsiveBreakpoint = 1260;
      const vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);

      if (vw < responsiveBreakpoint) {
        setAnimateAnswerDrop(false);
      }

      setTimeout(() => {
        setAnimateAnswerDrop(false);
      }, 2000);
    }
  }, [showPrompt, statement]);

  useEffect(() => {
    const newCardsToFetch = 5 - cards.answerCards.length;
    const newCards = newCardsToFetch < 5 ? getNewCards(cards.answerCards, newCardsToFetch) : [];
    const resetDeck = {
      answerCards: [...cards.answerCards, ...newCards],
      selectedAnswerCard: emptyArray(),
    };
    answerArray([]);
    setCards(resetDeck);
  }, [statement]);

  useEffect(() => {
    answerArray(cards.selectedAnswerCard);
  }, [cards.selectedAnswerCard]);

  const onDragUpdate = result => {
    const { source: cardOne, destination: cardTwo, draggableId } = result;
    if (!cardTwo) {
      return;
    }
    const destinationIndex = cardTwo.index;

    const domQuery = `[${queryAttr}='${draggableId}']`;
    const draggedDOM = document.querySelector(domQuery);

    if (!draggedDOM) {
      return;
    }

    const clientX = [...draggedDOM.parentNode.children].slice(0, destinationIndex).reduce((total, curr) => {
      const style = curr.currentStyle || window.getComputedStyle(curr);
      const marginLeft = parseFloat(style.marginLeft);
      const marginRight = parseFloat(style.marginRight);
      return total + curr.clientWidth + marginLeft + marginRight;
    }, 0);

    setPlaceholderProps({
      [cardTwo.droppableId]: {
        clientX,
      },
    });
  };

  const onDragEnd = result => {
    const { source: cardOne, destination: cardTwo } = result;
    // dropped outside the list
    if (!cardTwo) {
      return;
    }

    if (cardOne.droppableId === cardTwo.droppableId) {
      const items = reorder(cards[cardOne.droppableId], cardOne.index, cardTwo.index);
      const clonedCards = { ...cards };
      clonedCards[cardOne.droppableId] = items;
      setCards(clonedCards);
      answerArray(clonedCards.selectedAnswerCard);
    } else {
      const movedCards = move(cards[cardOne.droppableId], cards[cardTwo.droppableId], cardOne, cardTwo);
      const clonedCards = { ...cards };
      if (movedCards.selectedAnswerCard.length <= answerCount) {
        clonedCards.answerCards = movedCards.answerCards;
        clonedCards.selectedAnswerCard = movedCards.selectedAnswerCard;
        answerArray(movedCards.selectedAnswerCard);
        setCards(clonedCards);
      }

      if (movedCards.selectedAnswerCard.length > answerCount) {
        // If user is dragging a card into a complete answer set
        // A) work out if they dropped it at the end
        // == remove the first item in the answer array and move back to the answer cards array
        // B) if not dropped at the end
        // == remove the last item in the answer array and move back to the answer cards array
        const newCardAddedAtEnd = cardTwo.index + 1 > answerCount;

        if (newCardAddedAtEnd) {
          const lastSelectedAnswer = clonedCards.selectedAnswerCard.shift();
          const newSelectedAnswer = movedCards.selectedAnswerCard.pop();
          clonedCards.answerCards = [lastSelectedAnswer, ...movedCards.answerCards];
          clonedCards.selectedAnswerCard.push(newSelectedAnswer);
        } else {
          const lastSelectedAnswer = clonedCards.selectedAnswerCard.pop();
          const newSelectedAnswer = movedCards.selectedAnswerCard.shift();
          clonedCards.answerCards = [lastSelectedAnswer, ...movedCards.answerCards];
          clonedCards.selectedAnswerCard.unshift(newSelectedAnswer);
        }
        answerArray(clonedCards.selectedAnswerCard);
        setCards(clonedCards);
      }
    }
    setPlaceholderProps(defaultPlaceholderProps);
    setAnimateAnswerDrop(false);
  };

  const fiveNewCards = () => {
    answerArray([]);
    // newStatement();
    const clonedCards = { ...cards };
    clonedCards.selectedAnswerCard = [];
    clonedCards.answerCards = getFiveCards();
    setCards(clonedCards);
  };

  const undoSelection = () => {
    answerArray([]);
    const undoneDeck = {
      answerCards: [...cards.answerCards, ...cards.selectedAnswerCard],
      selectedAnswerCard: emptyArray(),
    };
    setCards(undoneDeck);
  };

  const tweetText = ` ${finalStatement} %23SillySiteChallenge+%23CardsAgainstGatsby+Made+By @Mammothdottech`;

  const handleHidePrompt = () => {
    setShowPrompt(false);
  };

  return (
    <div className="cards-section">
      {showPrompt && (
        <div className="cards-prompt">
          <img src={drag} alt="Drag and drop" />
          <span>Drop your card on top of the statement to play</span>
          <button className="button" type="button" onClick={handleHidePrompt}>
            Got It
          </button>
        </div>
      )}
      <DragDropContext onDragStart={() => setAnimateAnswerDrop(true)} onDragEnd={onDragEnd} onDragUpdate={onDragUpdate}>
        <div className="selected-answers">
          <div className={`card default ${animateAnswerDrop ? 'dragging' : ''}`}>
            <h1 dangerouslySetInnerHTML={{ __html: finalHtmlStatement }} />
            {statementComplete && (
              <Link
                className="button tweet-statement"
                to={`https://twitter.com/intent/tweet?url=https://cardsagainstgatsby.com/&text=${tweetText}`}
              >
                <Image image={{ url: twitter }} />
              </Link>
            )}
            {statementComplete && (
              <button className="button card-new-statement" type="button" aria-label="Undo" onClick={undoSelection}>
                <Image image={{ url: undo }} alt="Undo" />
              </button>
            )}
          </div>
          <Droppable
            droppableContent={cards.selectedAnswerCard}
            droppableId="selectedAnswerCard"
            direction={`${answerCount > 1 ? 'horizontal' : 'vertical'}`}
          >
            {(provided, snapshot) => (
              <div
                ref={provided.innerRef}
                className={`card-list selected-answer-list answer-count-${answerCount} ${
                  snapshot.isDraggingOver || animateAnswerDrop ? 'dragging' : ''
                }`}
                {...provided.droppableProps}
              >
                {cards.selectedAnswerCard.map((item, index) => (
                  <Draggable
                    key={item || index}
                    draggableId={item ? slugify(item, { strict: true }) : `${index}`}
                    index={index}
                  >
                    {(dragProvided, dragSnapshot) => (
                      <div
                        ref={dragProvided.innerRef}
                        {...dragProvided.draggableProps}
                        {...dragProvided.dragHandleProps}
                        className={`card ${dragSnapshot.isDragging ? 'dragging' : 'inactive'}`}
                        style={{ ...dragProvided.draggableProps.style }}
                      >
                        <p>{item}</p>
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
                <div
                  className="card-placeholder"
                  style={{
                    left: placeholderProps?.selectedAnswerCard?.clientX,
                  }}
                />
                {answerCount > 1 && !statementComplete && !snapshot.isDraggingOver && (
                  <div className="card-placeholder second-answer" />
                )}
              </div>
            )}
          </Droppable>
        </div>
        {!statementComplete && (
          <div className="answer-cards">
            <Droppable droppableContent={cards.answerCards} droppableId="answerCards" direction="horizontal">
              {(provided, snapshot) => (
                <div
                  ref={provided.innerRef}
                  className={`card-list answer-cards-list ${snapshot.isDraggingOver ? 'dragging' : ''}`}
                  // onMouseDown={() => setAnimateAnswerDrop(true)}
                  {...provided.droppableProps}
                >
                  {cards.answerCards.map((item, index) => (
                    <Draggable key={item} draggableId={slugify(item, { strict: true })} index={index}>
                      {(dragProvided, dragSnapshot) => (
                        <div
                          ref={dragProvided.innerRef}
                          {...dragProvided.draggableProps}
                          {...dragProvided.dragHandleProps}
                          className={`card ${dragSnapshot.isDragging ? 'active' : 'inactive'}`}
                          style={{ ...dragProvided.draggableProps.style }}
                        >
                          <p>{item}</p>
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                  <div
                    className="card-placeholder"
                    style={{
                      left: placeholderProps?.answerCards?.clientX,
                    }}
                  />
                </div>
              )}
            </Droppable>
          </div>
        )}
      </DragDropContext>
      <div className="card-actions">
        <button className="button" type="button" onClick={statementComplete ? newStatement : fiveNewCards}>
          {statementComplete ? 'Next Card' : 'New Cards'}
          <Image
            className="button-chevron"
            image={{ url: statementComplete ? chevron : shuffle }}
            alt="Get 5 new cards"
          />
        </button>
      </div>
    </div>
  );
};

export default AnswerPicker;
