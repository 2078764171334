export const statements = [
  `Lighthouse is useful for %`,
  `Monday morning coffee isn’t complete without %`,
  `The client is insisting that we include %`,
  `Truly great UX begins with %`,
  `I use Gatsby for all my websites because %`,
  `I use % To build accessible websites`,
  `50 percent of broken builds start with %`,
  `It’s Friday afternoon, which means %`,
  `I spent all day on the Gatsby subreddit and only learned about %`,
  `The best thing about Gatsby is %`,
  `I made an update and % is making my linter freak out`,
  `% messed up my performance score in Lighthouse`,
  `I use NPM over Yarn because %`,
  `Gatsby sites are % by design`,
  `Dude. % should be in the .gitignore`,
  `Gatsby. Create a % in the time it usually takes to build a prototype`,
  `Built-in % is Gatsby’s superpower`,
  `My junior dev told me that % is best practice`,
  `What’s that smell?? %`,
  `Gatsby is powered by the amazing Gatsby community and %`,
  `I found out that % was breaking my build`,
  `I use % to test the accessibility of my websites`,
  `My new start-up is going to disrupt the world of %`,
  `The difference between .map and .forEach is %`,
  `2020 was officially the year of %`,
  `If only % fixed my life the way it fixed my code`,
  `At my 3-month review my boss said % was starting to be a problem`,
  `Foundational programming begins with %`,
  `My tech career started with % and ended with %`,
  `Collaborate. Build. %. Repeat.`,
  `I'm paving the way for diversity in tech with %`,
  '% seems to make my tests fail',
  '% is the reason I drink',
  '% helps me code faster',
  `I got ninety-nine bugs but % ain't one`,
  'When your boss asks you to revert a commit. Surprise them with % instead',
  `I‘m trying to understand why % is causing %, and then breaking the build`,
  'The next big thing in Gatsby V3 is %',
  'Gatsby lifecycle events can be aggregated into: 1: Bootstrap. 2: Build. 3: %',
  'The difference between gatsby-ssr.js and gatsby-node.js is %',
  'I once put % in the onPostBuild hook ',
  'When you think Server Side Rendering you think of %',
  'An SSG takes % and turns it into %',
  'onRouteUpdate and % can be used to push data to Segment',
  'I never understood the Gatsby lifecycle events until I encountered %',
  'Why is my keyboard sticky? %',
  'Internet Explorer',
  'During a build, I like to think about %',
  'Before cleaning my cache I try %',
  '% brings all the GitHub stars to the yard',
  'I think my team lead would find % disturbing, yet oddly charming',
  'MAKE % GREAT AGAIN!',
  'Foo Bar',
  'My web development mentor told me that % is best practice',
  'Uh, hey team, I know this feature was my idea, but I’m having serious doubts about %',
  'Gatsby incremental builds can help with %',
  'My Gatsby build hangs at %',
  'I saw % change after updating Gatsby',
  '% means faster Gatsby builds',
  'Putting the % in JAMStack',
  'Lighthouse is flagging % as a performance issue',
  `I'm going to win the Gatsby Silly site contest by creating %`,
  `This bug is easy to fix, just use %`,
  `Sure, Gatsby is great, but have you ever tried %`,
  `gatsby... come for the performance, stay for the %`,
  `console.log('%')`,
  `Gatsby + % === %`,
  'I am doing a presentation on Gatsby and %',
  'rm -rf node_modules && %',
];

export const doubleStatementsForTesting = [
  `The best thing about % is %`,
  `% is useful for %`,
  `% isn’t complete without %`,
  `Truly great % begins with %`,
  `I use % for all my websites because %`,
  `I use % To build % websites`,
  `50 percent of % start with %`,
  'Gatsby takes % and turns it into %',
];

export const answers = [
  'spaghetti code',
  'a sea of troubles',
  'geese',
  'goblins',
  'deleting the root',
  'zoom meetings that should have been an email',
  'generic error messages',
  'a hot-fix, to fix a hot-fix, to fix a bug',
  'commiting a console.log',
  'pushing secrets to github',
  'pushing Lorem Ipsum to prod',
  'buttons that go nowhere',
  'breaking all your styles in one line',
  'broken links',
  'gatsby clean',
  'blocking search engines on production',
  'validation from my non-present father',
  'farting and walking away',
  `netlify’s 15 minute build timeout`,
  'microdosing LSD',
  'an unexpected meeting with HR',
  'pushing a typo fix to Gatsby to get a free t-shirt',
  'dying',
  'a Branding expert',
  'women in tech',
  'forgetting to put keys on .maps()',
  'brogrammers brogramming',
  'Steve Jobs and Bill Gates mud wrestling for supremacy',
  'carpal tunnel',
  'repetitive strain injury',
  'pretending to care',
  'whipping it out',
  'flightless birds',
  'my genitals',
  'my father’s unrelenting disappointment',
  'modafinil',
  'developers, developers, developers',
  'adderall',
  'cheating at Mario Kart',
  'synthwave',
  'code reviews',
  'using a .json file to store 1000s of data entries',
  'WordPress',
  'Covid-19',
  'men in tech',
  'Gatsby',
  'console logging',
  'Z-index: 999999999',
  'background-color: red',
  'using 301s to fix a routing typo',
  'consuming ALL the caffeine/taurine/guarana and seeing through the matrix',
  'my Spotify coding playlist',
  'tweeting web development pioneers asking why my code won’t work',
  'gatsby-node.js',
  'React Hooks',
  `vivid nightmares about the legacy code you've inherited`,
  'the screams… the terrible screams',
  '7 hard years',
  'using a class based component',
  'setting state in an infinite loop',
  `console.log('poop')`,
  'opposable thumbs',
  'Nicholas Cage',
  'Danny DeVito',
  'therapy',
  'tiny horse',
  'fading away into nothingness',
  'an AR-15 assault rifle',
  'Gatsby Cloud',
  'incremental builds',
  'Steve Ballmer',
  'a Gatsby plugin',
  'tearing it all down and starting again',
  'my relationship',
  'drinking alone',
  'self-loathing',
  'the miracle of childbirth',
  'peeing a little bit',
  'unwarranted confidence',
  'Viagra',
  'a lifetime of sadness',
  'ignoring tests',
  'the important! tag',
  'tech debt',
  'Donald J Trump',
  'Stack Overflow',
  'scrum training',
  'scotty from marketing',
  'a 20 minute build time',
  'throwing your Macbook out a 4th story window',
  'falcon punching your computer on the 8th failed build in a row',
  'alert("Hello! I am an alert box!!");',
];
