import React from 'react';
import { Image, Link } from 'components';
import gatsbyWhite from '../../images/gatsby-purple.svg';
// import logo from '../../images/logo.svg';

import './styles.scss';

const Intro = ({ handleEnterPlayMode }) => {
  return (
    <section className="intro">
      <div className="intro-logo">
        <div className="intro-logo-image">
          <Image image={{ url: gatsbyWhite }} alt="Cards Against Gatsby" />
        </div>
      </div>
      <h1 className="intro-title">
        Cards Against <span>Gatsby</span>
      </h1>
      <p>
        A #SillySiteChallenge based on the awesome{' '}
        <Link to="https://cardsagainsthumanity.com/">Cards Against Humanity.</Link>
      </p>
      <button className="button" type="button" onClick={handleEnterPlayMode}>
        Let's Play
      </button>
      <p>
        Built with {`<3`} by <Link to="https://mammoth.tech/">Woolly Mammoth</Link>
      </p>
    </section>
  );
};

export default Intro;
