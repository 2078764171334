import React, { useEffect } from 'react';
import LogRocket from 'logrocket';
import { SEO } from 'components';
import 'typeface-jost';
import '../../sass/global/styles.scss';
import './styles.scss';

const Layout = props => {
  const { children, customSEO } = props;
  useEffect(() => {
    if (process.env.NODE_ENV !== 'development') {
      LogRocket.init('woolly-mammoth/cards-against-gatsby'); // want to add in logic to prevent LogRocket firing for @equitise.com users
    }
  }, []);
  return (
    <>
      {/* <Header /> */}
      <main>
        {!customSEO && <SEO />}
        {children}
      </main>
      {/* <Footer /> */}
    </>
  );
};

export default Layout;
